import React from 'react';

import CssBaseline from '@material-ui/core/CssBaseline';

import { ErySettingsProvider } from 'ery-components/src/lib/ErySettings';

const withRoot = Component => {
  const WithRoot = props => (
    <>
      <CssBaseline />
      <ErySettingsProvider settings={{ product: 'behavery' }}>
        <Component {...props} />
      </ErySettingsProvider>
    </>
  );

  return WithRoot;
};

export default withRoot;
