import PropTypes from 'prop-types';
import React, { useState } from 'react';

import Box from '@material-ui/core/Box';
import { withStyles } from '@material-ui/core/styles';

import EryButton from '../EryButton';

import SignupDialog from './SignupDialog';

const styles = {
  clickable: {
    height: '100%',
  },
};

const SignupButton = ({ children, classes }) => {
  const [open, setOpen] = useState(false);

  return (
    <React.Fragment>
      <Box
        className={classes.clickable}
        onClick={() => {
          setOpen(true);
        }}
        label='Signup'
      >
        {children || EryButton}
      </Box>
      <SignupDialog open={open} onClose={() => setOpen(false)} />
    </React.Fragment>
  );
};

SignupButton.propTypes = {
  children: PropTypes.node,
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(SignupButton);
