import PropTypes from 'prop-types';
import React from 'react';

import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import { fade } from '@material-ui/core/styles/colorManipulator';
import { withStyles } from '@material-ui/core/styles';

import SignupButton from './SignupButton';

const styles = theme => ({
  banner: {
    cursor: 'pointer',
    position: 'relative',

    [theme.breakpoints.down('sm')]: {
      height: '200px',
    },
    [theme.breakpoints.up('md')]: {
      height: '400px',
    },
  },
  bannerText: {
    position: 'absolute',
    bottom: '1em',
    right: '1em',

    backgroundColor: fade(theme.palette.primary.light, 0.7),
    borderRadius: '1em',
    color: theme.palette.text.primary,
    padding: '1em',
  },
  image: {
    width: '100%',
    height: '100%',
    objectFit: 'cover',
    objectPosition: '50% 50%',
  },
});

const Banner = withStyles(styles)(({ classes, image, children }) => (
  <SignupButton>
    <Box className={classes.banner}>
      {image && <img className={classes.image} src={image} />}
      {children && (
        <Box className={classes.bannerText}>
          <Typography variant='h4'>{children}</Typography>
        </Box>
      )}
    </Box>
  </SignupButton>
));

Banner.propTypes = {
  image: PropTypes.string.isRequired,
  children: PropTypes.node,
};

export default Banner;
