/* eslint-disable consistent-return */
import auth0 from 'auth0-js';

export const webAuth = new auth0.WebAuth({
  domain: 'ery.auth0.com',
  clientID: process.env.AUTH0_CLIENT_ID,
  redirectUri: typeof window !== 'undefined' ? window.location.origin : '',
  audience: process.env.AUTH0_AUDIENCE,
  responseType: 'token id_token',
  scope: 'openid email profile',
});

export const setAccessToken = accessToken => {
  const date = new Date();

  date.setTime(date.getTime() + 24 * 60 * 60 * 1000);
  document.cookie = `ery_access_token=${accessToken}; domain=${
    process.env.MAIN_DOMAIN
  }; expires=${date.toUTCString()}; path=/; ${process.env.ENVIRONMENT === 'prod' ? 'secure;' : ''}`;
};

export const setCSRFToken = accessToken => {
  window
    .fetch(`${process.env.BACKEND_URL || ''}/csrf/`, {
      method: 'POST',
      headers: {
        Accept: 'application/json',
        Authorization: `Bearer ${accessToken}`,
        'Content-Type': 'application/json',
      },
      credentials: 'include',
    })
    .then(() => {
      const urlParams = new URLSearchParams(window.location.search);
      const next = urlParams.get('next');

      if (next) {
        window.location.href = next;
      } else {
        window.location.href = process.env.REDIRECT_URL;
      }
    });
};

export const facebookLogin = () =>
  new Promise((resolve, reject) => {
    webAuth.popup.authorize({ connection: 'facebook', owp: true }, (err, data) => {
      if (err) {
        reject(err);
        return;
      }

      setAccessToken(data.accessToken);
      setCSRFToken(data.accessToken);
    });
  });

export const googleLogin = () =>
  new Promise((resolve, reject) => {
    webAuth.popup.authorize({ connection: 'google-oauth2', owp: true }, (err, data) => {
      if (err) {
        reject(err);
        return;
      }

      setAccessToken(data.accessToken);
      setCSRFToken(data.accessToken);
    });
  });

export const linkedinLogin = () =>
  new Promise((resolve, reject) => {
    webAuth.popup.authorize({ connection: 'linkedin', owp: true }, (err, data) => {
      if (err) {
        reject(err);
        return;
      }

      setAccessToken(data.accessToken);
      setCSRFToken(data.accessToken);
    });
  });

export const loginWithCredentials = (username, password) =>
  new Promise((resolve, reject) => {
    webAuth.popup.loginWithCredentials(
      {
        connection: '',
        username,
        password,
      },
      (err, data) => {
        if (err) {
          return reject(err);
        }

        setAccessToken(data.accessToken);
        setCSRFToken(data.accessToken);
      }
    );
  });

export const signupAndAuthorize = params =>
  new Promise((resolve, reject) => {
    webAuth.signupAndAuthorize(params, (err, data) => {
      if (err) {
        return reject(err);
      }

      setAccessToken(data.accessToken);
      setCSRFToken(data.accessToken);
    });
  });

export default {
  facebookLogin,
  googleLogin,
  linkedinLogin,
  loginWithCredentials,
  signupAndAuthorize,
};
