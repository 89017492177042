import PropTypes from 'prop-types';
import React from 'react';

import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles({
  root: {
    alignItems: props => props.alignItems || 'center',
    height: '100%',
    margin: props => props.margin || 0,
    padding: props => props.padding || 0,
    justifyContent: props => props.justifyContent || 'flex-end',
  },
});

const EryButtons = props => {
  const classes = useStyles(props);

  return (
    <Grid container {...props} className={classes.root}>
      {props.children.map((child, i) => (
        <Grid item key={i}>
          <Box marginRight='1em'>{child}</Box>
        </Grid>
      ))}
    </Grid>
  );
};

EryButtons.propTypes = {
  alignItems: PropTypes.string,
  children: PropTypes.any,
};

export default EryButtons;

export const TestEryButtons = EryButtons;
