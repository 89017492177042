import React from 'react';
import { makeStyles } from '@material-ui/core/styles';

import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';

const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: props => props.justifyContent || 'flex-start',
    margin: props => props.margin || 0,
    width: props => props.width || 'initial',
    height: props => props.height || 'initial',
  },
  checkbox: {
    width: '20px',
    height: '20px',
    boxShadow: 'inset 0 1px 3px 0 rgba(0, 0, 0, 0.5)',
    background: props => props.inactiveColor || '#fafafa',
    marginRight: props => props.spacing || '0.5rem',
  },
  checked: {
    background: props => props.activeColor || theme.palette.primary.main,
  },
}));

const EryCheckbox = (props: Object) => {
  const classes = useStyles(props);
  const checked = props.value;

  const handleChange = () => {
    if (props.setFieldValue) {
      props.setFieldValue(props.name, !checked);
    } else {
      if (props.onChange) {
        props.onChange(!checked);
      } else if (props.onChangeValue) {
        props.onChangeValue(!checked);
      }
    }
  };

  return (
    <div className={classes.root}>
      <FormControlLabel
        control={<Checkbox checked={checked} onChange={handleChange} name={props.name} />}
        label={props.label}
      />
    </div>
  );
};

export default EryCheckbox;

export const TestEryCheckbox = EryCheckbox;
