import PropTypes from 'prop-types';
import React from 'react';

import classNames from 'classnames';

import SVG from 'react-inlinesvg';
import { makeStyles } from '@material-ui/core/styles';

import { useFormikContext } from 'formik';

import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';

const useStyles = makeStyles(theme => {
  return {
    root: {
      alignItems: props => props.alignItems || 'inherit',
    },
    default: {
      color: props => props.color || theme.palette.primary.main,
      border: props => `1px solid ${props.borderColor || props.color || theme.palette.primary.main}`,
      backgroundColor: props => props.backgroundColor || '#fff',
      boxShadow: '0 2px 4px 0 rgba(145, 145, 145, 0.5)',
      width: props => props.width || '150px',
      borderRadius: props => props.borderRadius || '4px',
      marginTop: props => props.mt || 0,
      marginBottom: props => props.mb || 0,
      marginLeft: props => props.ml || 0,
      marginRight: props => props.mr || 0,
      order: props => props.order,
    },
  };
});

const EryButton = ({ Icon, disabled, children, label, onClick, tabIndex, type, variant, ...props }) => {
  const classes = useStyles(props);
  const formikBag = useFormikContext();

  return (
    <Button
      className={!variant ? classNames(classes.default, classes.root) : classes.root}
      disabled={disabled || (formikBag && formikBag.isSubmitting)}
      onClick={onClick}
      tabIndex={tabIndex}
      type={type}
      variant={variant}
    >
      <React.Fragment>
        {Icon && (
          <Box marginRight='.5em'>
            <SVG src={Icon} />
          </Box>
        )}
        {label || children || null}
      </React.Fragment>
    </Button>
  );
};

EryButton.propTypes = {
  Icon: PropTypes.string,
  children: PropTypes.any,
  disabled: PropTypes.bool,
  label: PropTypes.string,
  onClick: PropTypes.func,
  tabIndex: PropTypes.number,
  type: PropTypes.string,
  variant: PropTypes.string,
};

export default EryButton;

export const TestEryButton = EryButton;
