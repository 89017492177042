import PropTypes from 'prop-types';
import React from 'react';

import { Form, Formik, useFormikContext } from 'formik';
import useRouter from 'found/useRouter';

import Box from '@material-ui/core/Box';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles({
  root: {
    height: 'inherit',
  },
});

const EryFormInner = props => {
  const formikBag = useFormikContext();
  const classes = useStyles();

  const handleKeyPress = (e: Object) => {
    if (e.key === 'Escape' && props.onPressEscapeKey) {
      props.onPressEscapeKey(formikBag);
    }

    if (e.key === 'Enter' && e.ctrlKey && props.onPressCtrlEnterKey) {
      props.onPressCtrlEnterKey ? props.onPressCtrlEnterKey(formikBag) : formikBag.submitForm();
    }
  };

  return (
    <Box className={classes.root} onKeyDown={handleKeyPress}>
      {props.children}
    </Box>
  );
};

EryFormInner.propTypes = {
  children: PropTypes.any,
  onPressCtrlEnterKey: PropTypes.func,
  onPressEscapeKey: PropTypes.func,
};

const EryForm = (props: Object) => {
  const classes = useStyles();
  const routerBag = useRouter();

  const MyForm = props.component || Form;

  return (
    <Formik
      initialValues={props.initialValues || {}}
      onSubmit={async (values, formikBag) => {
        formikBag.setSubmitting(true);
        props.onSubmit && (await props.onSubmit(values, formikBag));
        props.resetOnSubmit && formikBag.resetForm({});
        formikBag.setSubmitting(false);
      }}
      validate={props.validate}
    >
      {({ dirty }) => {
        // Disabled for now
        if (false && routerBag && dirty) {
          routerBag.router.addNavigationListener(() => 'You have unsaved input. Are you sure you want to leave this page?', {
            beforeUnload: true,
          });
        }
        return (
          <MyForm className={classes.root}>
            <EryFormInner {...props} />
          </MyForm>
        );
      }}
    </Formik>
  );
};

export default EryForm;
